<script setup>
const props = defineProps(["modelValue"]);
const emit = defineEmits(["selectBible", "selectBook"]);

const bibleCode = computed(() => props.modelValue?.location?.bibleModel?.bibleShortName);
const location = computed(() => {
  const bookName = props.modelValue?.location?.bookModel?.bookNameCanonShort;
  const chapterNum = props.modelValue?.location?.chapterNum;

  return `${bookName} ${chapterNum}`;
});
</script>
<template>
  <div class="parallel-panel">
    <div class="action-btn border bible-code" @click="emit('selectBible')">
      {{ bibleCode }}
    </div>
    <div class="action-btn border bible-ref" @click="emit('selectBook')">
      {{ location }}
    </div>
  </div>
</template>
<style scoped>
.parallel-panel {
  width: 100%;
  background-color: var(--root-green);
  min-height: 40px;
  max-height: 40px;
  color: var(--root-white);
  display: flex;
  max-width: var(--width-mobile);
  position: fixed;
  box-shadow: 0px 7px 12px 0px rgba(0, 191, 164, 0.25);
  z-index: 2;
}
.border {
  border-right: solid 1.5px rgba(255, 255, 255, 1);
  height: 25px;
  margin: auto 0;
  padding: 0 2px;
}

.action-btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 110px;
}

.bible-ref {
  width: 130px;
  font-size: 18px;
}

.back {
  display: flex;
  cursor: pointer;
}
.back img {
  width: 20px;
}
.bible-code {
  font-size: 18px;
}
</style>
